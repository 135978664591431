import { Url, UrlType, UserProfileType } from 'src/generated/graphql'
import { ModalPaths } from 'src/routes'

const modalPathsValues = Object.values(ModalPaths)

export const isInternalLink = (url: Url) => url.type === UrlType.Internal

export const isModalLink = (url: Url) =>
  modalPathsValues.includes(url.value as ModalPaths)

export const userProfileTypeToPath = (type: UserProfileType): string =>
  `/${type.toLowerCase()}`

export const formatExternalLink = (input: string): string => {
  if (input.startsWith('mailto:') || input.startsWith('https://')) {
    return input
  } else {
    return `https://${input}`
  }
}
