/**
 * Adding feature flag below
 * feature flag key and value MUST be same
 */
export enum Flags {
  trainingAndAssessmentInitial = 'trainingAndAssessmentInitial',
  trainingAndAssessmentInitialTrainer = 'trainingAndAssessmentInitialTrainer',
  eventsBookAndPayInitial = 'eventsBookAndPayInitial',
  eventsBookAndPayClub = 'eventsBookAndPayClub',
  certificationsReleaseSubscriptions = 'certificationsReleaseSubscriptions',
  prismicContent = 'prismicContent',
  lesMillsDance = 'lesMillsDance',
  limitedEditionPhase1 = 'limitedEditionPhase1',
  limitedEditionPhase2 = 'limitedEditionPhase2',
  searchClubRefactorPhase1 = 'searchClubRefactorPhase1',
  nordicMarketRelease = 'nordicMarketRelease',
  findClassesSearchRadius = 'findClassesSearchRadius',
  addressUpdates = 'addressUpdates',
  addressValidationBanner = 'addressValidationBanner',
  clubBookPayQWS = 'clubBookPayQWS',
  instructorBookPayQWS = 'instructorBookPayQWS',
  isClubBookedEventQWS = 'isClubBookedEventQWS',
  instructorBookedQWS = 'instructorBookedQWS',
  trainerExperienceQWS = 'trainerExperienceQWS',
  innovationBookAndPay = 'innovationBookAndPay',
  innovationTrainingJourney = 'innovationTrainingJourney',
  enabledCapacityUpdates = 'enabledCapacityUpdates',
  newFindWorkMobileExperience = 'newFindWorkMobileExperience',
  clubHiringImprovement = 'clubHiringImprovement',
}

export type FlagsProps = Record<Flags, boolean>
